import React from "react"
import Layout from '../../components/layout';
import FrontPage from '../../components/frontpage';
import { graphql } from "gatsby";

const EHHT = ({data}) => {
    return (    
      <Layout children={<FrontPage serverUrl={data.site.siteMetadata.serverUrl} />} baseurl={"/ei-haukku-haavaa-tee"} />
    );
  };

export default EHHT;

export const query = graphql`
  query EHHTQuery {
    site {
      siteMetadata {
        siteUrl
        serverUrl
        title
        description
      }
    }
  }
`