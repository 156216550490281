import React from "react";
import Hero from "./hero";
import CampaignPhases from "./campaign-phases";
import CampaignEnd from "./campaign-end";
import "@typeform/embed/build/css/popup.css"; // import necessary CSS

export default function FrontPage({serverUrl}) {
    return (
        <React.Fragment>
            <Hero></Hero>
            <CampaignPhases></CampaignPhases>
            <CampaignEnd></CampaignEnd>
        </React.Fragment>
    );
}