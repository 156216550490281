import React from 'react';
import styled from 'styled-components';
import Box  from "@material-ui/core/Box";
import Grid  from "@material-ui/core/Grid";
import {device, deviceMax} from "../helpers/devicesizes";
import Typography from '@material-ui/core/Typography';
import RaportoiSVG from '../../svg-assets/raportoi.svg';
import KeskusteleSVG from '../../svg-assets/keskustele.svg';
import ArvosteleSVG from '../../svg-assets/arvostele.svg';

const StyledBox = styled(Box)`
    text-align:center;
    @media ${device.tablet} {
        padding: 40px 50px 10px 50px;
    }
    @media ${deviceMax.tablet} {
        padding: 25px 11px;
    }
`;

const StyledGrid = styled(Grid)`
    max-width: 650px;
    margin:25px auto;
    text-align: left;
`;

const StyledGridContainer = styled(Grid)`
    height: 70px;
`;

export default function CampaignPhases() {
    return (
        <StyledBox bgcolor="secondary.main">
            <Typography variant="h5">Miten Matkahuollon “Ei haukku haavaa tee” -kampanja toimi?</Typography>
            <StyledGrid container spacing={0} alignItems="flex-start">
                <Grid item xs={4}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <StyledGridContainer item xs={12} sm={4}>
                        <RaportoiSVG />
                        </StyledGridContainer>
                        <Grid item xs={12} sm={8}>
                        <Typography color="primary">1. Asiakkaat raportoivat ongelmia julkisesti</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <StyledGridContainer item xs={12} sm={4}>
                        <KeskusteleSVG />
                        </StyledGridContainer>
                        <Grid item xs={12} sm={8}>
                        <Typography color="primary">2. Matkahuolto osallistui keskusteluun</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <StyledGridContainer item xs={12} sm={4}>
                        <ArvosteleSVG />
                        </StyledGridContainer>
                        <Grid item xs={12} sm={8}>
                        <Typography color="primary">3. Asiakkaat arvostelivat saamansa palvelun</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledGrid>
            <Typography variant="body2" color="primary" style={{padding:'10px 25px 10px 25px'}}>Kampanja-aika oli 17.11.-13.12.2020.</Typography>
        </StyledBox>
    );
}