import React from 'react';
import Header from './header';
import Footer from './footer';
import Container from '@material-ui/core/Container';
import { Helmet } from "react-helmet";
import MUICookieConsent from 'material-ui-cookie-consent';


export default function Layout({children, pagemeta, baseurl}) {
    let title ="Matkahuolto - Ei haukku haavaa tee | NoNoNo";
    let description ="Onko sinulla Matkahuollolle kritiikkiä pakettipalveluista? Osallistu keskusteluun. Palaute on lahja.";
    title = pagemeta && 'title' in pagemeta ? pagemeta.title : title;
    description = pagemeta && 'description' in pagemeta ? pagemeta.description : description;
    return (
      <React.Fragment>
        <Helmet>
        <html lang="fi" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="apple-mobile-web-app-title" content="nonono.fi" />
          <meta name="application-name" content="nonono.fi" />

          <meta property="og:title" content={title} />
          <meta property="og:description"  content={description}/>
          <meta property="og:image" content="https://nonono.fi/some3.jpg" />
          <meta property="og:image:width" content="500" />
          <meta property="og:image:width" content="301" />
          <meta property="og:site_name" content="nonono.fi"/>
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://nonono.fi/" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:image" content="https://nonono.fi/some.jpg" />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" data-react-helmet="true" content={description} />

          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="mask-icon" href="/svg-assets/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <Container maxWidth={false} disableGutters={true}>
        <Header baseurl={baseurl}></Header>
        {children}
        <Footer baseurl={baseurl}></Footer>
        </Container>
        <MUICookieConsent 
          acceptButtonLabel="OK"
          cookieName="NoNoNoCookieConsent"
          message="Tämä sivusto käyttää evästeitä..."
          snackbarAnchor={{horizontal: 'right', vertical: 'bottom'}}
        />
      </React.Fragment>
    )
  }