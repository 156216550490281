import React from 'react';
import styled from 'styled-components';
import Button  from "@material-ui/core/Button";
import Box  from "@material-ui/core/Box";
import {navigate} from 'gatsby'; 


const StyledBox = styled(Box)`
    padding: 100px 0;
`; 
export default function CampaignEnd() {
    const onClick = () => {
        navigate("/ei-haukku-haavaa-tee/matkahuolto-kokemuksia");
    }
    const onResultsClick = () => {
        navigate("https://www.matkahuolto.fi/blog/se-mika-meille-on-ollut-tarkeaa-ei-ehka-ole-ollut-asiakkaille-tarkeaa");
    }

    return (
        <React.Fragment>
        <StyledBox display="flex" justifyContent="center" alignItems="center">
            <div style={{display:'grid'}}>
                <Button variant="contained" size="large" color="primary" onClick={onClick}>Lue asiakkaiden raportoimia kokemuksia</Button>
                <Button variant="contained" size="large" color="primary" style={{marginTop:'30px'}} onClick={onResultsClick}>Katso kampanjan tulokset</Button>
            </div>
        </StyledBox>

        </React.Fragment>
    );
}